import React from 'react';
import * as styles from './Button.module.css';

const Button = ({children, onClick, color, filled, dataAos}) => {
    const colors = ['white', 'purple'];
    color = colors.includes(color) ? color : colors[0];

    return (
        <div data-aos={dataAos}>
            <button onClick={onClick} className={`${styles.button} ${styles[color]} ${filled ? styles.filled : ''}`} >
                {children}
            </button>
        </div>
    );
};

export default Button;