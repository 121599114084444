import React, {useState} from 'react';
import styles from './MobileSlidableContent.module.css';

const MobileSlidableContent = ({children}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const scrollContainer = React.cloneElement(children, { onScroll: handleScrollEvent});
    const scrollableChildrenAmount = scrollContainer.props.children.length;

    const renderIndicators = (amountOfIndicators) => {
        let indicators = [];
        for (let i = 0; i < amountOfIndicators; i++){
            indicators.push(<div key={i} className={`${styles.circle} ${currentIndex === i ? styles.active : ''}`}/>);
        }
        return indicators;
    };

    function handleScrollEvent (event) {
        const childElements = [...event.target.children];
        for (let i = 0; i < childElements.length; i++){
            const el = childElements[i];
            const rect = el.getBoundingClientRect();
            const isInView = rect.left >= 0 && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
            if (isInView){
                setCurrentIndex(i);
                break;
            }
        }
    }

    return (
        <>
            <div className={styles.container}>
                {scrollContainer}
            </div>
            <div className={styles.sliderIndicators}>{renderIndicators(scrollableChildrenAmount)}</div>
        </>
    );
};

export default MobileSlidableContent;