import React, {useState, useEffect} from 'react';
import styles from './ContactForm.module.css';
import FormInput from '../form-input/FormInput';
import Button from '../button/Button';
import {submitPromoForm} from '../../requests/ContactForm';
import SubmitStatusIndicator from '../submit-status-indicator/SubmitStatusIndicator';
import LoadingIndicator from '../loading-indicator/LoadingIndicator';

const ContactForm = ({comment = ''}) => {
    const [loading, setLoading] = useState(false);

    const formStatusTypes = {NONE: 0, SUCCESS: 1, FAIL: 2};
    const [formStatus, setFormStatus] = useState(formStatusTypes.NONE);

    let initialFormData = {
        fullName: {value: '', valid: false, touched: false, placeholder: 'Ваше имя', maxLength: 64},
        phoneNumber: {value: '', valid: false, touched: false, placeholder: '(__) ___-__-__', maxLength: 15, type: 'phone'},
        comment: {value: '', valid: true, touched: false, placeholder: 'Комментарий', maxLength: 512},
    };

    useEffect(() => {
        if(comment){
            setFormData(prevState => {
                prevState.comment.value = comment;
                prevState.comment.touched = false;
                prevState.comment.valid = true;
                return {...prevState}
            });
        }
    }, [comment]);

    const [formData, setFormData] = useState(initialFormData);

    const getCleanNumber = (phoneNumber) => {
        return phoneNumber.replace(/\D/g, '')
    };

    const validators = {
        fullName: (value) => {
            return value.length >= 3;
        },
        phoneNumber: (value) => {
            return /^[\d]{9}$/.test(getCleanNumber(value));
        }
    };

    let handleSubmit = (event) => {
        setFormStatus(formStatusTypes.NONE);
        event.preventDefault();
        const allFieldsValid = Object.keys(formData).every(key => formData[key].valid === true);
        if (allFieldsValid){
            const dataToSubmit = {};
            for (let key in formData){
                if(formData.hasOwnProperty(key)){
                    dataToSubmit[key] = formData[key].value;
                }
            }

            dataToSubmit['phoneNumber'] = `+375 ${dataToSubmit['phoneNumber']}`;

            setLoading(true);

            submitPromoForm(dataToSubmit)
                .then(processSuccessfulSubmit)
                .catch(processFailedSubmit);
        }else{
            markInvalidFieldsAsTouched();
        }
    };

    let processSuccessfulSubmit = () => {
        setFormData(initialFormData);
        setFormStatus(formStatusTypes.SUCCESS);
        setLoading(false);
    };

    let processFailedSubmit = (error) => {
        setFormStatus(formStatusTypes.FAIL);
        setLoading(false);
        if (error instanceof TypeError) {
            alert('Проверьте подключение к интернету.');
        }else{
            alert(error.message);
        }
    };

    let handleInputChange = (event) =>{
        const name = event.target.name;
        const value = event.target.value;
        const isValid = validators[name] ? validators[name](value) : true;
        setFormData({...formData, [name]: {...formData[name], value: value, valid: isValid, touched: true}});
        setFormStatus(formStatusTypes.NONE);
    };

    let markInvalidFieldsAsTouched = () => {
        let formDataCopy = {...formData};
        for (let [key, value] of Object.entries(formDataCopy)){
            console.log(key, value);
            if(!value.valid)
                value.touched = true;
        }
        setFormData(formDataCopy);
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            {Object.entries(formData).map(([key, field]) => {
                return (
                    <FormInput key={key} name={key} placeholder={field.placeholder} type={field.type? field.type: 'text'}
                               value={field.value} valid={field.valid} touched={field.touched}
                               onChange={handleInputChange} maxLength={field.maxLength}/>
                );
            })}
            <div className={styles.controlsContainer}>
                <Button color='purple' filled={true}>Отправить</Button>
                <div className={styles.statusMessageWrapper}>{formStatus === formStatusTypes.NONE ? '' :
                    <SubmitStatusIndicator submitSucceeded={formStatus === formStatusTypes.SUCCESS}/>}</div>
            </div>
            <LoadingIndicator shown={loading} style={{top: '-5px', left: '-5px', right: '-5px', bottom: '-5px'}} text='Идёт отправка...'/>
        </form>
    );
};

export default ContactForm;