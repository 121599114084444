const APIRoot = process.env.REACT_APP_API_ROOT || '/api/';

export function submitPromoForm(formFields){
    return fetch(`${APIRoot}contact`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(formFields)
    }).then(async response => {
        if (response.status !== 200)
            throw new Error(await response.json());
        return response.json().catch(() => '');
    });
}