import React from 'react';
import * as styles from './IntroBanner.module.css';
import {ReactComponent as BottomWaveImg} from '../../assets/images/intro-bottom-wave.svg';
import {ReactComponent as Rocket} from '../../assets/images/rocket.svg';
import {ReactComponent as RocketThrust} from '../../assets/images/rocket-thrust.svg';
const IntroBanner = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.stars}></div>
            <div className={styles.stars2}></div>
            <div className={styles.stars3}></div>
            <div className={styles.bottomWaveWrapper}>
                <BottomWaveImg/>
            </div>
            <div className={styles.content}>
                <div className={styles.rocketWithThrustWrapper}>
                    <div className={styles.rocketWrapper}>
                        <Rocket/>
                    </div>
                    <div className={styles.rocketThrustWrapper}>
                        <RocketThrust/>
                        <RocketThrust/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IntroBanner;