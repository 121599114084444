import React from 'react';
import {HashLink} from 'react-router-hash-link';
function ScrollLink({className, navigateTop, onClick, to, children}){
    return <HashLink className={className} to={to} onClick={onClick}
                     scroll={(el) => {
                            const offset = 120;
                            const top = navigateTop ? 0 : el.offsetTop - offset;
                            window.scrollTo({ top: top, left: 0, behavior: 'smooth' })
                        }}>
        {children}
    </HashLink>
}
export default ScrollLink;