import React from 'react';
import * as styles from './Title.module.css';

const Title = ({children, style}) => {
    return (
        <h2 className={styles.title} style={style} data-aos="zoom-in">
            {children}
        </h2>
    );
};

export default Title;