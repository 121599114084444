import React from 'react';
import * as styles from './Text.module.css';

const Text = ({children, style}) => {
    return (
        <p className={styles.text} style={style} data-aos="fade-down">
            {children}
        </p>
    );
};

export default Text;