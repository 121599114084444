import React, {useContext} from 'react';
import * as styles from './FullWidthBanner.module.css';
import Text from '../text/Text';
import Button from '../button/Button';
import Title from '../title/Title';
import {ReactComponent as ManLeaningImg} from '../../assets/images/man-leaning-mirrored.svg';
import ContactModalFormContext from '../../context/ContactModalFormContext';
import ContactForm from '../contact-form/ContactForm';

const FullWidthBanner = () => {
    const showContactForm = useContext(ContactModalFormContext);
    return (
        <div className={styles.fullWidthBanner}>
            <div className={styles.text}>
                <Title>Нужен совет профессионала?</Title>
                <Text>
                    <span data-aos="fade-down">Мы относимся к нашим клиентам как к партнёрам, а не источнику дохода. Поэтому мы сделали всё возможное, чтобы консультация являлась полезной услугой, а не попыткой продать типовое решение. Мы всегда посоветуем оптимальный вариант и назовём честную стоимость.</span>
                    <br/>
                    <br/>
                    <span data-aos="fade-down">Оформите заявку - это совершенно бесплатно. Наш специалист проведет грамотную консультацию по телефону, учитывая все тонкости и нюансы Вашей ситуации. И, если Вам будет удобно, назначит личную встречу.</span>
                    <br/>
                    <br/>
                    <span data-aos="fade-down">И помните - нет ничего невозможного! Мы с удовольствием выслушаем Ваши самые грандиозные и смелые идеи и с удовльствием поможем их реализовать.</span>
                </Text>
                <Button color='purple' filled={false} dataAos='fade-up' onClick={() => showContactForm('Нужен совет профессионала')}>Запросить бесплатную консультацию</Button>
            </div>
            <div className={styles.formWrapper}>
                <div className={styles.formImageWrapper}>
                    <ManLeaningImg/>
                </div>
                <div className={styles.contactFormContainer}>
                    <h3>Консультация от эксперта</h3>
                    <ContactForm/>
                </div>
            </div>
        </div>
    );
};

export default FullWidthBanner;