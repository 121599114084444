import React from 'react';
import * as styles from './ContentWrapper.module.css';

const ContentWrapper = ({children, id}) => {
    return (
        <div className={styles.contentWrapper} id={id}>
            {children}
        </div>
    );
};

export default ContentWrapper;