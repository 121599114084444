import React from 'react';
import * as styles from './FeatureList.module.css';
import FeatureListItem from '../feature-list-item/FeatureListItem';
import {ReactComponent as GrowthIcon} from '../../assets/images/growth-icon.svg';
import {ReactComponent as ProtectionIcon} from '../../assets/images/protection-icon.svg';
import {ReactComponent as SnapIcon} from '../../assets/images/snap-icon.svg';
import {ReactComponent as UpdateIcon} from '../../assets/images/update-icon.svg';
import MobileSlidableContent from '../mobile-slidable-content/MobileSlidableContent';

const FeatureList = () => {
    const features = [
        {
            title: 'Автоматизация и рост',
            subtitle: 'Мы предоставляем услуги по разработке, внедрению и масшабированию систем, необходимых для продуктивной работы и стабильного роста Вашего бизнеса',
            icon: <GrowthIcon/>
        },
        {
            title: 'Защита бизнеса',
            subtitle: 'Видео- и аудиоаналитика на основе нейронных сетей, позволяющая существенно сократить кражи, мошенничество, исключить нарушение рабочих регламентов и многое другое',
            icon: <ProtectionIcon/>
        },
        {
            title: 'Простота',
            subtitle: 'Легкая и понятная интеграция. А если нет штатных специалистов - мы поможем',
            icon: <SnapIcon/>
        },
        {
            title: 'Актуальность',
            subtitle: 'Мы постоянно радуем клиентов бесплатными автоматическими обновлениями, а все оценки основываются только на свежих данных',
            icon: <UpdateIcon/>
        },
    ];
    return (
        <div className={styles.wrapper}>
            <MobileSlidableContent>
                <div className={styles.featureList}>
                    {features.map((feature, i) => <FeatureListItem title={feature.title} subtitle={feature.subtitle} icon={feature.icon} key={i} index={i}/>)}
                </div>
            </MobileSlidableContent>
        </div>
    );
};

export default FeatureList;