import React from 'react';
import * as styles from './FeatureListItem.module.css';

const FeatureListItem = ({title, subtitle, icon, index}) => {
    return <div className={styles.featureItem}  data-aos="fade-in" data-aos-delay={100 * index}>
        <div className={styles.iconWrapper} data-aos='fade-down' data-aos-delay={150 * index}><div className={styles.iconBg}></div>{icon}</div>
        <h3 className={styles.title} data-aos='fade-up' data-aos-delay={150 * index}>{title}</h3>
        <p className={styles.subtitle} data-aos="zoom-in" data-aos-delay={150 * index}>{subtitle}</p>
    </div>;
};

export default FeatureListItem;