import React, {useContext} from 'react';
import * as styles from './Intro.module.css';
import ContentWrapper from '../content-wrapper/ContentWrapper';
import Button from '../button/Button';
import ContactModalFormContext from '../../context/ContactModalFormContext';
import LaptopSlider from '../laptop-slider/LaptopSlider';
import ScrollLink from '../scroll-link/ScrollLink';
import {ReactComponent as IntroImage} from '../../assets/images/intro.svg';
import IntroBanner from '../intro-banner/IntroBanner';
const Intro = () => {
    const showContactForm = useContext(ContactModalFormContext);
    return (
        <div className={styles.wrapper}>
            <ContentWrapper>
                <div className={styles.introContent}>
                    <div className={styles.introUpper}>
                        <h2 className={styles.introCaption} data-aos="zoom-in">
                            Высокие технологии для бизнеса
                        </h2>
                        <p className={styles.introSubtitle} data-aos="fade-down">Автоматизация и оптимизация бизнеса на всех уровнях.</p>
                        <p className={styles.introSubtitle} data-aos="fade-down">Используя богатый опыт и высокий уровень экспертизы, мы помагаем нашим клиентам решать сложнейшие задачи бизнеса и легко адаптироваться к стремительно меняющимся условиям на рынке.</p>
                        <div className={styles.buttonsContainer}>
                            <ScrollLink to='#advantages'>
                                <Button color="white" filled={true}  dataAos="fade-гз">Подробнее</Button>
                            </ScrollLink>
                            <Button onClick={()=> showContactForm()} color="purple" filled={true} dataAos="fade-down">Связаться с нами</Button>
                        </div>
                    </div>

                    <div className={styles.introImageWrapper}>
                        {/*<IntroImage/>*/}
                    </div>
                    <IntroBanner/>
                    {/*<LaptopSlider/>*/}
                </div>
            </ContentWrapper>
        </div>
    );
};

export default Intro;