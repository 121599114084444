import React from 'react';
import * as styles from './PromoBlockItem.module.css';

const PromoBlockItem = ({children}) => {
    return (
        <div className={styles.promoBlockItem}>
            {children}
        </div>
    );
};

export default PromoBlockItem;