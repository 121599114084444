import React, {useEffect, useRef} from 'react';
import styles from './ModalWindow.module.css';
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';
import {ReactComponent as CrossIcon} from '../../assets/images/cross-icon.svg'

const ModalWindow = ({children, shown, setShown, title}) => {
    const contentRef = useRef(null);

    useEffect(() => {
        if(shown){
            disableBodyScroll(document.getElementsByName('body'));
        }else{
            enableBodyScroll(document.getElementsByName('body'));
        }
    }, [shown]);

    return (
        <div className={`${styles.container} ${!shown ? styles.hidden: ''}`}>
            <div className={styles.window} >
                <div className={styles.topPanel}>
                    <h3 className={styles.modalTitle}>{title}</h3>
                    <div className={styles.closeButtonWrapper}>
                        <button onClick={() => setShown(false)}>
                            <CrossIcon/>
                        </button>
                    </div>
                </div>
                <div className={styles.content} ref={contentRef}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default ModalWindow;