export function useScrollByHashOnLoad() {
    const eventListener = function() {
        const { hash } = window.location;
        if (hash) {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            const offset = 120;
            const top = element.offsetTop - offset;
            if (element)
                window.scrollTo({ top: top, left: 0, behavior: 'smooth' });
        }
    };
    window.addEventListener('load', eventListener);
    return () => window.removeEventListener('load', eventListener);
}