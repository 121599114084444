import React from 'react';
import * as styles from './BurgerButton.module.css'

const BurgerButton = ({isExpanded, isPageScrolled, onClick}) => {
    const color = !isPageScrolled && !isExpanded ? 'var(--color-white)' : 'var(--color-accent)';
    return (
        <button className={`${styles.button} ${isExpanded ? styles.isExpanded : ''}`} onClick={onClick} >
            <div className={styles.panel} style={{backgroundColor: color}}></div>
            <div className={styles.panel} style={{backgroundColor: color}}></div>
            <div className={styles.panel} style={{backgroundColor: color}}></div>
        </button>
    );
};

export default BurgerButton;