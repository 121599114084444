import React from 'react';
import styles from './LoadingIndicator.module.css';
import {ReactComponent as Logo} from '../../assets/images/logo.svg';

const LoadingIndicator = ({shown, style, text}) => {
    return (
        <div style={style} className={`${!shown ? styles.hidden : ''} ${styles.wrapper}`}>
            <Logo className={styles.loaderImage}/>
            <div className={styles.text}>{text}</div>
        </div>
    );
};

export default LoadingIndicator;