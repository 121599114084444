import React from 'react';
import * as styles from './Footer.module.css';
import {ReactComponent as Logo} from '../../assets/images/logo.svg';
import {ReactComponent as OutroImage} from '../../assets/images/rocket.svg';
import {ReactComponent as WALogo} from '../../assets/images/whatsapp-logo.svg';
import {ReactComponent as TelegramLogo} from '../../assets/images/telegram-logo.svg';
import ContactForm from '../contact-form/ContactForm';
import ScrollLink from '../scroll-link/ScrollLink';

const Footer = ({navLinks}) => {
    return (
        <div className={styles.footerWrapper}>
            <div className={styles.footerContent}>
                <div className={styles.column}>
                    <ScrollLink className={styles.logo} to="#">
                        <div className={styles.logoImageWrapper} data-aos="zoom-in">
                            <Logo/>
                        </div>
                        {/*<div className={styles.logoText} data-aos="fade-right">IT Global</div>*/}
                    </ScrollLink>
                    <div className={styles.motoText}>ООО «ИТ-ГЛОБАЛ» © 2022</div>
                    {/*<div className={styles.imageWrapper}  data-aos="zoom-in">*/}
                        {/*<Logo/>*/}
                    {/*</div>*/}
                </div>
                <div className={styles.columnGroup}>
                    <div className={styles.column} data-aos="fade-up">
                        <h4 className={styles.columnTitle} data-aos="fade-up">Навигация</h4>
                        <div className={styles.linksContainer}>
                            {navLinks.map((l, i) =>  <ScrollLink key={i} className={styles.footerLink} to={l.href}>{l.name}</ScrollLink>)}
                        </div>
                    </div>
                    <div className={styles.column} data-aos="fade-up">
                        <h4 className={styles.columnTitle} data-aos="fade-up">Контакты</h4>
                        <div className={styles.linksContainer}>
                            <a className={styles.footerLink} href='mailto:hello@itglobal.by'><span className={styles.label}>Email:</span> hello@itglobal.by</a>
                            <a className={styles.footerLink} href='tel:+375447709709'><span className={styles.label}>Тел:</span> +375 (44) 7 709 709</a>
                        </div>
                        <div className={styles.socialsContainer}>
                            <a className={styles.socialLink} href='https://wa.me/message/XYM3AUSWQ5VEA1' title='WhatsApp' target='_blank' referrerPolicy='no-referrer'>
                                <WALogo/>
                            </a>
                            <a className={styles.socialLink} href='https://telegram.me/analytics_data' title='Telegram' target='_blank' referrerPolicy='no-referrer'>
                                <TelegramLogo/>
                            </a>
                        </div>
                    </div>
                    <div className={`${styles.column} ${styles.formWrapper}`} data-aos="fade-up" id='contacts'>
                        <h4 className={styles.columnTitle} data-aos="fade-up">Связаться</h4>
                        <ContactForm/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;