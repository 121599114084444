import React from 'react';
import styles from './SubmitStatusIndicator.module.css';
import {ReactComponent as CheckCircleIcon} from '../../assets/images/check-circle-icon.svg';
import {ReactComponent as CrossCircleIcon} from '../../assets/images/cross-circle-icon.svg';

const SubmitStatusIndicator = ({submitSucceeded}) => {
    return (
        <div className={`${styles.wrapper} ${submitSucceeded ? styles.succeeded : styles.failed}`}>
            {submitSucceeded ? <CheckCircleIcon/> : <CrossCircleIcon/>}
            <div className={styles.text}>
                {submitSucceeded ? <span>Заявка отправлена</span> : <span>Возникла ошибка при отправке</span>}
            </div>
        </div>
    );
};

export default SubmitStatusIndicator;