import React, {useContext} from 'react';
import PromoBlockItem from '../promo-block-item/PromoBlockItem';
import Title from '../title/Title';
import Button from '../button/Button';
import Text from '../text/Text';
import CarsImg from '../../assets/images/cars-top.png';
import PersonImg from '../../assets/images/person-with-data.png';
import HappyClientImg from '../../assets/images/happy-client.svg';
import BusinessRaceImg from '../../assets/images/business-race.svg';
import PositiveReviewsImg from '../../assets/images/positive-reviews.svg';
import ContactModalFormContext from '../../context/ContactModalFormContext';

const PromoBlock = () => {
    const showContactForm = useContext(ContactModalFormContext);
    return (
        <>
            <PromoBlockItem>

                <img src={PersonImg} alt='' data-aos='zoom-in'/>
                <div>
                    <Title>Как мы работаем?</Title>
                    <Text>
                        <span data-aos="fade-down">Собрав многолетний опыт работы и команды экспертов из различных сфер бизнеса, мы ежедневно проектируем и внедряем передовые решения, помогающие бизнесам лидировать в своих областях.</span>
                        <br/><br/>
                        <span data-aos="fade-down">Наши системы работают 24 часа в сутки, 7 дней в неделю без праздников, обедов и выходных.</span>
                        <br/><br/>
                        <span data-aos="fade-down">Наши специалисты всегда готовы решать возникающие вопросы, вне зависимости от дня недели и времени на часах.</span>
                    </Text>

                    <Button color="purple" filled={true} dataAos='fade-up' onClick={() => showContactForm('Хочу узнать больше')}>Узнайте больше</Button>
                </div>
            </PromoBlockItem>
            <PromoBlockItem>
                <div>
                    <Title>Наши ценности</Title>
                    <Text>
                        <span data-aos="fade-down">Мы понимаем потребности наших клиентов и специфику рынка.</span>
                        <br/><br/>
                        <span data-aos="fade-down">Наша задача - это помогать вам решать текущие задачи, чтобы Ваш бизнес мог зарабатывать.</span>
                        <br/><br/>
                        <span data-aos="fade-down">Не менее важная задача для нас - воплощать в жизнь ваши самые смелые идеи, чтобы Ваш бизнес стремительно рос и развивался.</span>
                        <br/><br/>
                    </Text>
                    <Button color="purple" filled={true} dataAos='fade-up' onClick={() => showContactForm('Хочу узнать больше')}>Узнайте больше</Button>
                </div>
                <img src={PositiveReviewsImg} alt='' data-aos='zoom-in'/>
            </PromoBlockItem>

            <PromoBlockItem>
                <img src={BusinessRaceImg} alt='' data-aos='zoom-in'/>
                <div>
                    <Title>Гонка</Title>
                    <Text>
                        <span data-aos="fade-down">В гонке с конкурентами лидирует бизнес, который выжимает максимум из современных высокотехнологичных иснтрументов.</span>
                        <br/><br/>
                        <span data-aos="fade-down">Современные технологии не упускают те нюансы, которые зачастую незаметны человеку.</span>
                        <br/><br/>
                        <span data-aos="fade-down">Наши решения позволяют производить тонкую настройку и оптимизировать различные бизнес-процессы, начиная маркетинговыми кампаниями и заканчивая работой склада.</span>
                    </Text>
                    <Button color="purple" filled={true} dataAos='fade-up' onClick={() => showContactForm('Хочу узнать больше')}>Узнайте больше</Button>
                </div>
            </PromoBlockItem>
        </>
    );
};

export default PromoBlock;