import { useEffect, useState } from 'react';

export function useScrollTop(){
    const debounceMs = 10;
    const getScrollTop = () => window.pageYOffset;
    const [scrollTop, setScrollTop] = useState(getScrollTop());
    const updateState = debounce(() => setScrollTop(getScrollTop()), debounceMs);
    useEffect(() => {
        window.addEventListener('scroll', updateState);
        return () => window.removeEventListener('scroll', updateState);
    });
    return scrollTop;
}

function debounce(fn, ms) {
    let timer;
    return _ => {
        clearTimeout(timer);
        timer = setTimeout(_ => {
            timer = null;
            fn.apply(this, arguments);
        }, ms);
    };
}